import React from "react"
import { Link as ScrollLink } from "react-scroll"
import { mdiArrowDown } from "@mdi/js"

import { Button, Heading, Wrapper } from "components"

const CONTENT_START_ID = "quote"

const IntroSection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {data.title && (
        <Wrapper id={id} pt="calc(32px + 48px + 128px)">
          {data.title && <Heading size={900}>{data.title}</Heading>}
          {/* Scroll down button */}
          {/* We wrap the Button with the ScrollLink component because it doesn't behave nicely when using "as" prop */}
          {data.buttonText && (
            <ScrollLink smooth={true} to={CONTENT_START_ID}>
              <Button
                as="div"
                iconAfter={mdiArrowDown}
                intent="primary"
                appearance="primary"
                height={[40, 48]}
                mt="layout.5"
              >
                {data.buttonText}
              </Button>
            </ScrollLink>
          )}
        </Wrapper>
      )}
    </>
  )
}

export default IntroSection
