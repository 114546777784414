import React from "react"
import Image from "gatsby-image/withIEPolyfill"
import css from "@styled-system/css"

import {
  AspectRatio,
  Box,
  Button,
  FlexGrid,
  FormFieldLabel,
  FormFieldInput,
  FormFieldTextarea,
  FormFieldSelect,
  Heading,
  RichText,
  Text,
  Wrapper,
} from "components"

const Form = () => (
  <Box
    as="form"
    name="contactForm"
    method="post"
    action="/submitted/"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
    encType="multipart/form-data"
  >
    <Heading size={900} mb={["layout.2", "layout.4"]}>
      All Inquiries
    </Heading>
    <input type="hidden" name="form-name" value="contactForm" />
    <input type="hidden" name="bot-field" />
    <Box
      css={css({
        "> *:not(:last-of-type)": {
          mb: "layout.1",
        },
      })}
    >
      <FormFieldInput
        label="Full name"
        inputProps={{
          name: "fullName",
          placeholder: "Jane Doe",
          type: "text",
          required: true,
        }}
        name="name"
      />
      <FormFieldInput
        label="Email"
        inputProps={{
          name: "email",
          placeholder: "name@example.com",
          type: "email",
          required: true,
        }}
        name="email"
      />
      <FormFieldInput
        label="Phone (optional)"
        inputProps={{
          name: "phone",
          placeholder: "(555) 555-5555",
          type: "tel",
          required: false,
        }}
        name="phone"
      />
      <FormFieldSelect
        label="Subject"
        selectProps={{
          name: "subject",
          children: (
            <>
              <option selected disabled>
                Select a subject
              </option>
              <option>General</option>
              <option>Customer service</option>
              <option>Accounting</option>
              <option>Sales</option>
              <option>Dispatch</option>
            </>
          ),
          required: true,
          width: "100%",
        }}
      />
      <FormFieldInput
        label="Building address (optional)"
        inputProps={{
          name: "buildingAddress",
          placeholder: "Address",
          type: "text",
          required: false,
        }}
        name="buildingAddress"
      />
      <FormFieldTextarea
        label="Message"
        textareaProps={{
          name: "message",
          placeholder: "Write your message",
          width: "100%",
          rows: 5,
          // height: null,
          required: true,
        }}
      />
      <Box>
        {/* <Heading size={100} mb="layout.1">
          Attachment (Optional)
        </Heading> */}
        <FormFieldLabel label="Attachment (optional)" />
        <Text as="div">
          <input
            type="file"
            name="attachment"
            // onChange={() => {
            //   setFileReady(true)
            // }}
          />
        </Text>
      </Box>
    </Box>
    <Button intent="primary" appearance="primary" width="100%" mt="layout.3">
      Send Message
    </Button>
  </Box>
)

const ContactSection = ({ data, id }) => {
  return (
    <>
      <Wrapper id={id}>
        <FlexGrid
          content={[
            {
              width: ["100%", null, "50%"],
              children: (
                <Box>
                  <Form />
                </Box>
              ),
            },
            {
              width: ["100%", null, "50%"],
              children: (
                <Box borderRadius={16} bg="wash" overflow="hidden">
                  {/* MAP */}
                  <AspectRatio ratio="4:3" bg="wash">
                    {data.mapImage && (
                      <Image
                        fluid={data.mapImage && data.mapImage.fluid}
                        alt={data.mapImage && data.mapImage.alt}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                        objectFit="cover"
                        objectPosition="center"
                      />
                    )}
                  </AspectRatio>

                  {/* DIRECT INFO */}
                  <Box p="layout.4">
                    {data.description && (
                      <RichText
                        content={{
                          html: data.description,
                        }}
                        size={400}
                      />
                    )}
                  </Box>
                </Box>
              ),
            },
          ]}
          gutterY={[7]}
        />
      </Wrapper>
    </>
  )
}

export default ContactSection
