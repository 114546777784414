import React from "react"

import {
  Box,
  Button,
  Flex,
  FlexGrid,
  Heading,
  Link,
  Text,
  Wrapper,
} from "components"

const MoreInfoSection = ({ data, id }) => {
  return (
    <>
      <Wrapper id={id}>
        <FlexGrid
          content={[
            {
              width: ["100%", "50%"],
              children: (
                <Box>
                  <Flex
                    flexDirection="column"
                    flex={1}
                    p="layout.4"
                    borderRadius={8}
                    bg="wash"
                  >
                    <Heading size={800} textAlign="center">
                      {data.block1.title}
                    </Heading>
                    <Text size={500} mt="layout.2" textAlign="center">
                      {data.block1.subtitle}
                    </Text>
                    <Flex justifyContent="center" mt="layout.4">
                      <Button as={Link} to={data.block1.buttonLink}>
                        {data.block1.buttonText}
                      </Button>
                    </Flex>
                  </Flex>
                </Box>
              ),
            },
            {
              width: ["100%", "50%"],
              children: (
                <Box>
                  <Flex
                    flexDirection="column"
                    flex={1}
                    p="layout.4"
                    borderRadius={8}
                    bg="wash"
                  >
                    <Heading size={800} textAlign="center">
                      {data.block2.title}
                    </Heading>
                    <Text size={500} mt="layout.2" textAlign="center">
                      {data.block2.subtitle}
                    </Text>
                    <Flex justifyContent="center" mt="layout.4">
                      <Button as={Link} to={data.block2.buttonLink}>
                        {data.block2.buttonText}
                      </Button>
                    </Flex>
                  </Flex>
                </Box>
              ),
            },
          ]}
        />
      </Wrapper>
    </>
  )
}

export default MoreInfoSection
