import React from "react"
import css from "@styled-system/css"
import Image from "gatsby-image/withIEPolyfill"

import {
  Box,
  Button,
  Checkbox,
  FlexGrid,
  FormFieldInput,
  FormFieldLabel,
  FormFieldSelect,
  FormFieldTextarea,
  Heading,
  RichText,
  Text,
  Wrapper,
} from "components"

// const BusinessForm = ({ services }) => (
//   <Box
//     as="form"
//     name="businessForm"
//     method="post"
//     action="/submitted/"
//     data-netlify="true"
//     data-netlify-honeypot="bot-field"
//     pt="layout.2"
//   >
//     <input type="hidden" name="form-name" value="businessForm" />
//     <input type="hidden" name="bot-field" />
//     <Box
//       css={css({
//         "> *:not(:last-of-type)": {
//           mb: "layout.2",
//         },
//       })}
//     >
//       <FormFieldInput
//         label="Your full name"
//         inputProps={{
//           name: "fullName",
//           placeholder: "Jane Doe",
//           type: "text",
//           required: true,
//         }}
//       />
//       <FormFieldInput
//         label="Email"
//         inputProps={{
//           name: "email",
//           placeholder: "name@example.com",
//           type: "email",

//           required: true,
//         }}
//       />
//       <FormFieldInput
//         label="Phone (Optional)"
//         inputProps={{
//           name: "phone",
//           placeholder: "(555) 555-5555",
//           type: "tel",
//           required: true,
//         }}
//         name="phone"
//       />
//       <FormFieldInput
//         label="Business address"
//         inputProps={{
//           name: "address",
//           placeholder: "Business address",
//           required: true,
//         }}
//       />
//       <FormFieldInput
//         label="City/Town"
//         inputProps={{
//           name: "city",
//           placeholder: "City or town",
//           required: true,
//         }}
//       />
//       <FormFieldInput
//         label="Business name"
//         inputProps={{
//           name: "businessName",
//           placeholder: "Business name",
//           type: "text",
//           required: true,
//         }}
//       />
//       <FormFieldSelect
//         label="Number of employees"
//         selectProps={{
//           name: "numberOfEmployees",
//           children: (
//             <>
//               <option value="" selected disabled>
//                 Choose one
//               </option>
//               <option>1-10</option>
//               <option>11-20</option>
//               <option>21-50</option>
//               <option>51-100</option>
//               <option>100+</option>
//             </>
//           ),
//           width: "100%",
//           required: true,
//         }}
//       />
//       <Box>
//         <FormFieldLabel label="What services are you interested in?" />
//         {services &&
//           services.map((service, index) => (
//             <Box
//               display="inline-block"
//               mr="layout.2"
//               key={"businessService" + index}
//               minWidth="33%"
//             >
//               <Checkbox
//                 children={service.data.name.text}
//                 name={service.data.name && service.data.name.text}
//                 size={500}
//               />
//             </Box>
//           ))}
//       </Box>
//       <Heading children="How many bins do you currently have?" />
//       <Box mt={["layout.2"]}>
//         <FormFieldInput
//           label="Mixed Recyling"
//           inputProps={{
//             name: "currentNumberOfBinsMixedRecycling",
//             placeholder: "",
//           }}
//         />
//         <FormFieldInput
//           label="Garbage"
//           inputProps={{
//             name: "currentNumberOfBinsGarbage",
//             placeholder: "",
//           }}
//         />
//         <FormFieldInput
//           label="Organic"
//           inputProps={{
//             name: "currentNumberOfBinsOrganic",
//             placeholder: "",
//           }}
//         />
//         <FormFieldInput
//           label="Other"
//           inputProps={{
//             name: "currentNumberOfBinsOther",
//             placeholder: "",
//           }}
//         />
//       </Box>
//       <Heading children="What size of bins do you currently have?" />
//       <Box mt={["layout.2"]}>
//         <FormFieldInput
//           label="Mixed Recyling"
//           inputProps={{
//             name: "sizeOfBinsMixedRecycling",
//             placeholder: "",
//           }}
//         />
//         <FormFieldInput
//           label="Garbage"
//           inputProps={{
//             name: "sizeOfBinsGarbage",
//             placeholder: "",
//           }}
//         />
//         <FormFieldInput
//           label="Organic"
//           inputProps={{
//             name: "sizeOfBinsOrganic",
//             placeholder: "",
//           }}
//         />
//         <FormFieldInput
//           label="Other"
//           inputProps={{
//             name: "sizeOfBinsOther",
//             placeholder: "",
//           }}
//         />
//       </Box>
//       <Heading children="How often are the bins picked up?" />
//       <Box mt={["layout.2"]}>
//         <FormFieldInput
//           label="Mixed Recyling"
//           inputProps={{
//             name: "binPickupFrequencyMixedRecyling",
//             placeholder: "",
//           }}
//         />
//         <FormFieldInput
//           label="Garbage"
//           inputProps={{
//             name: "binPickupFrequencyGarbage",
//             placeholder: "",
//           }}
//         />
//         <FormFieldInput
//           label="Organic"
//           inputProps={{
//             name: "binPickupFrequencyOrganic",
//             placeholder: "",
//           }}
//         />
//         <FormFieldInput
//           label="Other"
//           inputProps={{
//             name: "binPickupFrequencyOther",
//             placeholder: "",
//           }}
//         />
//       </Box>
//       <FormFieldSelect
//         label="Would you like lids on your bins?*"
//         selectProps={{
//           name: "wouldLikeLids",
//           children: (
//             <>
//               <option selected disabled>
//                 Choose one
//               </option>
//               <option>Yes</option>
//               <option>No</option>
//             </>
//           ),
//           required: true,
//           width: "100%",
//         }}
//       />
//       <Text
//         children={`*If bins are placed outside, lids must be on`}
//         size={1}
//         mt={-4}
//         mb={4}
//       />
//       <FormFieldSelect
//         label="How did you hear about us?"
//         selectProps={{
//           name: "marketingChannel",
//           children: (
//             <>
//               <option selected disabled>
//                 Choose one
//               </option>
//               <option>Word of mouth</option>
//               <option>Truck</option>
//               <option>Online Search</option>
//               <option>Social Media</option>
//               <option>Current User of BluPlanet</option>
//               <option>Other</option>
//             </>
//           ),
//           required: true,
//           width: "100%",
//         }}
//       />
//       <FormFieldTextarea
//         label="Reason for Inquiry"
//         textareaProps={{
//           name: "reasonForInquiry",
//           placeholder: "Do you have a specific reason for inquiring?",
//         }}
//       />
//       <FormFieldTextarea
//         label="Additional comments"
//         textareaProps={{
//           name: "binPickupFrequency",
//           placeholder: "Do you have any additional comments?",
//         }}
//       />
//       <Box>
//         <Heading size={100} mb="layout.1">
//           Attachment (Optional)
//         </Heading>
//         <Text as="div">
//           <input
//             type="file"
//             name="attachment"
//             // onChange={() => {
//             //   setFileReady(true)
//             // }}
//           />
//         </Text>
//       </Box>
//     </Box>
//     <Button intent="primary" appearance="primary" mt="layout.3">
//       Send me a Quote
//     </Button>
//   </Box>
// )

const MultiFamilyForm = ({ services, bins }) => (
  <Box
    as="form"
    name="multiFamilyForm"
    method="post"
    action="/submitted/"
    encType="multipart/form-data"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
    pt="layout.2"
  >
    <input type="hidden" name="form-name" value="multiFamilyForm" />
    <input type="hidden" name="bot-field" />
    <Box
      css={css({
        "> *:not(:last-of-type)": {
          mb: "layout.2",
        },
      })}
    >
      <FormFieldInput
        label="Your full name"
        inputProps={{
          name: "fullName",
          placeholder: "Jane Doe",
          type: "text",
          required: true,
        }}
      />
      <FormFieldInput
        label="Email"
        inputProps={{
          name: "email",
          placeholder: "name@example.com",
          type: "email",
          required: true,
        }}
      />
      <FormFieldInput
        label="Phone"
        inputProps={{
          name: "phone",
          placeholder: "(555) 555-5555",
          type: "tel",
          required: true,
        }}
        name="phone"
      />
      <FormFieldInput
        label="Building address"
        inputProps={{
          name: "buildingAddress",
          placeholder: "Building address",
          required: true,
        }}
      />
      <FormFieldInput
        label="Business/Building Name (Optional)"
        inputProps={{
          name: "building",
          placeholder: "Building name",
          required: false,
        }}
      />
      <Box>
        <FormFieldLabel label="What services are you interested in? (Check all that apply)" />
        {services &&
          services.map((service, index) => (
            <Box
              display="inline-block"
              mr="layout.2"
              key={"service" + index}
              minWidth="33%"
            >
              <Checkbox
                children={service.data.name && service.data.name.text}
                name={service.data.name && service.data.name.text}
                size={500}
              />
            </Box>
          ))}
        <Box display="inline-block" mr="layout.2" minWidth="33%">
          <Checkbox children="Other" name="Other" size={500} />
        </Box>
      </Box>
      {/* <FormFieldInput
        label="City/Town"
        inputProps={{
          name: "city",
          placeholder: "City or town",
          required: true,
        }}
      />
      <FormFieldInput
        label="Building name"
        inputProps={{
          name: "buildingName",
          required: true,
          placeholder: "Building name",
          type: "text",
        }}
      /> */}
      {/* <FormFieldInput
        label="Condo Corporation Number (if known)"
        inputProps={{
          name: "condoCorporationNumber",
          placeholder: "Condo Corporation Number (if known)",
          type: "text",
        }}
      />
      <FormFieldInput
        label="Number of units"
        inputProps={{
          name: "numberOfUnits",
          required: true,
          placeholder: "Number of units in the the property",
          type: "number",
        }}
      /> */}
      {/* <FormFieldSelect
        label="Waste room/enclosure location"
        selectProps={{
          name: "wasteRoomType",
          children: (
            <>
              <option value="" selected disabled>
                Choose one
              </option>
              <option>Above ground</option>
              <option>Below ground</option>
              <option>Above and below ground</option>
            </>
          ),
          width: "100%",
          required: true,
        }}
      /> */}
      <Box>
        <FormFieldLabel label="When is the best time to reach you? (Check all that apply)" />
        <Box display="inline-block" mr="layout.2" minWidth="33%">
          <Checkbox children="8AM - 10AM" name="reachMeAt8To10" size={500} />
        </Box>
        <Box display="inline-block" mr="layout.2" minWidth="33%">
          <Checkbox children="10AM - 12PM" name="reachMeAt10To12" size={500} />
        </Box>
        <Box display="inline-block" mr="layout.2" minWidth="33%">
          <Checkbox children="12PM - 3PM" name="reachMeAt12To3" size={500} />
        </Box>
        {/* {services &&
          services.map((service, index) => (
            <Box
              display="inline-block"
              mr="layout.2"
              key={"multi-familyservice" + index}
              minWidth="33%"
            >
              <Checkbox
                children={service.data.name.text}
                name={service.data.name && service.data.name.text}
                size={500}
              />
            </Box>
          ))} */}
      </Box>
      <Box>
        <FormFieldLabel label="Preferred Contact Method" />
        <Box
          display="inline-flex"
          mr="layout.2"
          minWidth="33%"
          alignItems="center"
        >
          <input
            type="radio"
            name="contactMethod"
            value="Email"
            size={500}
            required={true}
          />
          <Text children="Email" />
        </Box>
        <Box
          display="inline-flex"
          mr="layout.2"
          minWidth="33%"
          alignItems="center"
        >
          <input
            type="radio"
            name="contactMethod"
            value="Email"
            size={500}
            required={true}
          />
          <Text children="Phone" />
        </Box>
        <Box
          display="inline-flex"
          mr="layout.2"
          minWidth="33%"
          alignItems="center"
        >
          <input
            type="radio"
            name="contactMethod"
            value="Both"
            size={500}
            required={true}
          />
          <Text children="Both" />
        </Box>
        {/* {services &&
          services.map((service, index) => (
            <Box
              display="inline-block"
              mr="layout.2"
              key={"multi-familyservice" + index}
              minWidth="33%"
            >
              <Checkbox
                children={service.data.name.text}
                name={service.data.name && service.data.name.text}
                size={500}
              />
            </Box>
          ))} */}
      </Box>
      {/* <Heading children="How many bins do you currently have?" />
      <Box mt={["layout.2"]}>
        <FormFieldInput
          label="Mixed Recyling"
          inputProps={{
            name: "currentNumberOfBinsMixedRecycling",
            placeholder: "",
          }}
        />
        <FormFieldInput
          label="Garbage"
          inputProps={{
            name: "currentNumberOfBinsGarbage",
            placeholder: "",
          }}
        />
        <FormFieldInput
          label="Organic"
          inputProps={{
            name: "currentNumberOfBinsOrganic",
            placeholder: "",
          }}
        />
        <FormFieldInput
          label="Other"
          inputProps={{
            name: "currentNumberOfBinsOther",
            placeholder: "",
          }}
        />
      </Box>
      <Heading children="What size of bins do you currently have?" />
      <Box mt={["layout.2"]}>
        <FormFieldInput
          label="Mixed Recyling"
          inputProps={{
            name: "sizeOfBinsMixedRecycling",
            placeholder: "",
          }}
        />
        <FormFieldInput
          label="Garbage"
          inputProps={{
            name: "sizeOfBinsGarbage",
            placeholder: "",
          }}
        />
        <FormFieldInput
          label="Organic"
          inputProps={{
            name: "sizeOfBinsOrganic",
            placeholder: "",
          }}
        />
        <FormFieldInput
          label="Other"
          inputProps={{
            name: "sizeOfBinsOther",
            placeholder: "",
          }}
        />
      </Box>
      <Heading children="How often are the bins picked up?" />
      <Box mt={["layout.2"]}>
        <FormFieldInput
          label="Mixed Recyling"
          inputProps={{
            name: "binPickupFrequencyMixedRecyling",
            placeholder: "",
          }}
        />
        <FormFieldInput
          label="Garbage"
          inputProps={{
            name: "binPickupFrequencyGarbage",
            placeholder: "",
          }}
        />
        <FormFieldInput
          label="Organic"
          inputProps={{
            name: "binPickupFrequencyOrganic",
            placeholder: "",
          }}
        />
        <FormFieldInput
          label="Other"
          inputProps={{
            name: "binPickupFrequencyOther",
            placeholder: "",
          }}
        />
      </Box>
      <FormFieldSelect
        label="Would you like lids on your bins?*"
        selectProps={{
          name: "wouldLikeLids",
          children: (
            <>
              <option selected disabled>
                Choose one
              </option>
              <option>Yes</option>
              <option>No</option>
            </>
          ),
          required: true,
          width: "100%",
        }}
      />
      <Text
        children={`*If bins are placed outside, lids must be on`}
        size={1}
        mt={-4}
        mb={4}
      /> */}
      <FormFieldSelect
        label="How did you hear about us?"
        selectProps={{
          name: "marketingChannel",
          children: (
            <>
              <option selected disabled>
                Choose one
              </option>
              <option>Word of mouth</option>
              <option>Truck</option>
              <option>Online Search</option>
              <option>Social Media</option>
              <option>Referral</option>
              <option>Current User of BluPlanet</option>
              <option>Other</option>
            </>
          ),
          required: true,
          width: "100%",
        }}
      />
      {/* <FormFieldTextarea
        label="Reason for Inquiry"
        textareaProps={{
          name: "reasonForInquiry",
          placeholder: "Do you have a specific reason for inquiring?",
        }}
      /> */}
      <FormFieldSelect
        label="Do you currently pay additional fees and surcharges on your waste hauling services? "
        selectProps={{
          name: "currentFees",
          children: (
            <>
              <option selected disabled>
                Choose one
              </option>
              <option>Yes</option>
              <option>No</option>
              <option>I don’t know</option>
              <option>
                I am already with BluPlanet so I don’t pay any additional fees
              </option>
            </>
          ),
          required: true,
          width: "100%",
        }}
      />
      <FormFieldInput
        label="Reason for inquiring"
        inputProps={{
          name: "inquiryReason",
          placeholder: "What is your reason for inquiring?",
        }}
      />
      <FormFieldTextarea
        label="Additional comments"
        textareaProps={{
          name: "binPickupFrequency",
          placeholder: "Do you have any additional comments?",
        }}
      />
      <Box>
        {/* <Heading size={100} mb="layout.1">
          Attachment (Optional)
        </Heading> */}
        <FormFieldLabel label="Attachment (optional)" />
        <Text as="div">
          <input
            type="file"
            name="attachment"
            // onChange={() => {
            //   setFileReady(true)
            // }}
          />
        </Text>
      </Box>
    </Box>

    <Button intent="primary" appearance="primary" mt="layout.3">
      Request Proposal
    </Button>
  </Box>
)

const QuoteSection = ({ data, id }) => {
  return (
    <>
      <Wrapper id={id}>
        <Box mx={["-24px", "-64px"]} overflow="hidden">
          <Box
            p={["layout.3", "layout.6"]}
            borderRadius={[0, null, null, 16]}
            bg="wash"
          >
            <Heading size={900} mb={["layout.2", "layout.4"]}>
              Request a proposal
            </Heading>
            <FlexGrid
              content={[
                {
                  width: ["100%", null, "50%"],
                  children: (
                    <Box>
                      <Box>
                        <MultiFamilyForm
                          services={data.services}
                          bins={data.bins}
                        />
                        {/* <Tabs
                          data={[
                            {
                              label: "Multi-Family",
                              content: (
                                <MultiFamilyForm
                                  services={data.services}
                                  bins={data.bins}
                                />
                              ),
                            },
                            {
                              label: "Business",
                              content: (
                                <BusinessForm services={data.services} />
                              ),
                            },
                            // {
                            //   label: "Municipality",
                            //   content: (
                            //     <MunicipalityForm services={data.services} />
                            //   ),
                            // },
                          ]}
                        /> */}
                      </Box>
                    </Box>
                  ),
                },
                {
                  width: ["100%", null, "50%"],
                  children: (
                    <Box>
                      <Box>
                        <RichText
                          content={{ html: data.bodyContent }}
                          size={500}
                        />
                      </Box>
                      {data?.features && data?.features?.length >= 1 && (
                        <Box mt="layout.6">
                          <FlexGrid
                            gutterX={[2]}
                            gutterY={[2]}
                            content={data.features.map((feature, index) => ({
                              width: ["50%", null, "50%"],
                              children: (
                                <Box
                                  key={index}
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="center"
                                  height="100%"
                                  pt="layout.2"
                                  pb="layout.2"
                                  px="layout.2"
                                  bg="rgba(0,0,0,0.05)"
                                  borderRadius={12}
                                >
                                  {feature.icon && feature.icon.fluid && (
                                    <Box position="relative" width="50%">
                                      <Box pb="100%" />
                                      <Image
                                        fluid={feature.icon.fluid}
                                        alt={feature.icon.alt}
                                        objectFit="contain"
                                        objectPosition="center"
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          width: "100%",
                                          height: "100%",
                                        }}
                                      />
                                    </Box>
                                  )}
                                  {feature.heading && (
                                    <Text
                                      as="div"
                                      size={400}
                                      fontWeight="bold"
                                      mt="layout.1"
                                      textAlign="center"
                                    >
                                      {feature.heading?.text}
                                    </Text>
                                  )}
                                </Box>
                              ),
                            }))}
                          />
                        </Box>
                      )}
                    </Box>
                  ),
                },
              ]}
              gutterX={[7]}
            />
          </Box>
        </Box>
      </Wrapper>
    </>
  )
}

export default QuoteSection

// const MunicipalityForm = ({ services }) => (
//   <Box
//     as="form"
//     name="municipalityForm"
//     method="post"
//     action="/submitted/"
//     data-netlify="true"
//     data-netlify-honeypot="bot-field"
//     pt="layout.2"
//   >
//     <input type="hidden" name="form-name" value="municipalityForm" />
//     <input type="hidden" name="bot-field" />
//     <Box
//       css={css({
//         "> *:not(:last-of-type)": {
//           mb: "layout.2",
//         },
//       })}
//     >
//       <FormFieldInput
//         label="Your full name"
//         inputProps={{
//           name: "fullName",
//           placeholder: "Jane Doe",
//           type: "text",
//           required: true,
//         }}
//       />
//       <FormFieldInput
//         label="Email"
//         inputProps={{
//           name: "email",
//           placeholder: "name@example.com",
//           type: "email",
//           required: true,
//         }}
//       />
//       <FormFieldInput
//         label="Municipality name"
//         inputProps={{
//           name: "municipalityName",
//           placeholder: "Municipality name",
//           type: "text",
//           required: true,
//         }}
//       />
//       <FormFieldSelect
//         label="Number of properties"
//         selectProps={{
//           name: "numberOfProperties",
//           children: (
//             <>
//               <option value="" selected disabled>
//                 Choose one
//               </option>
//               <option>1-10</option>
//               <option>11-20</option>
//               <option>20-100</option>
//               <option>100+</option>
//             </>
//           ),
//           required: true,
//           width: "100%",
//         }}
//       />
//       <Box>
//         <FormFieldLabel label="What services are you interested in?" />
//         {services &&
//           services.map((service, index) => (
//             <Box
//               display="inline-block"
//               mr="layout.2"
//               key={"municipialityService" + index}
// minWidth = "33%"
//             >
//               <Checkbox
//                 children={service.data.name && service.data.name.text}
//                 name={service.data.name && service.data.name.text}
//                 size={500}
//               />
//             </Box>
//           ))}
//       </Box>
//       <FormFieldSelect
//         label="How did you hear about us?"
//         selectProps={{
//           name: "marketingChannel",
//           children: (
//             <>
//               <option value="" selected disabled>
//                 Choose one
//               </option>
// <option>Word of mouth</option>
// <option>Truck</option>
// <option>Online Search</option>
// <option>Social Media</option>
// <option>Current User of BluPlanet</option>
// <option>Other</option>
//             </>
//           ),
//           width: "100%",
//           required: true,
//         }}
//       />
//     </Box>
//     <Button intent="primary" appearance="primary" mt="layout.3">
//       Send me a Quote
//     </Button>
//   </Box>
