import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

import { SEO } from "components"

// Page sections
import IntroSection from "./_introSection"
import MoreInfoSection from "./_moreInfoSection"
import QuoteSection from "./_quoteSection"
import ContactSection from "./_contactSection"

function ContactPage() {
  const staticData = useStaticQuery(graphql`
    {
      prismicContact {
        data {
          seo_title {
            text
          }
          seo_description {
            text
          }
          seo_image {
            url
            alt
          }
          hero_title {
            text
          }
          quote_button_text
          map_image {
            fluid(maxWidth: 1200) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          contact_description {
            html
          }
          quote_description {
            html
          }
          info_block_1_title {
            text
          }
          info_block_1_subtitle
          info_block_1_button_text
          info_block_1_button_link {
            slug
            type
            url
          }
          info_block_2_title {
            text
          }
          info_block_2_subtitle
          info_block_2_button_text
          info_block_2_button_link {
            slug
            type
            url
          }
          quote_features {
            icon {
              fluid(maxWidth: 256) {
                ...GatsbyPrismicImageFluid
              }
              alt
            }
            heading {
              text
            }
          }
        }
      }
      allPrismicBin(sort: { fields: data___name___text, order: DESC }) {
        nodes {
          data {
            name {
              text
            }
          }
        }
      }
      allPrismicService(sort: { fields: data___name___text, order: DESC }) {
        nodes {
          uid
          data {
            name {
              text
            }
          }
        }
      }
    }
  `)
  const previewData =
    typeof window !== "undefined" && window.__PRISMIC_PREVIEW_DATA__
  const finalData = mergePrismicPreviewData({ staticData, previewData })
  return (
    <>
      <SEO
        title={
          finalData.prismicContact.data.seo_title &&
          finalData.prismicContact.data.seo_title.text
        }
        description={
          finalData.prismicContact.data.seo_description &&
          finalData.prismicContact.data.seo_description.text
        }
        image={
          finalData.prismicContact.data.seo_image &&
          finalData.prismicContact.data.seo_image.url
        }
        imageAlt={
          finalData.prismicContact.data.seo_image &&
          finalData.prismicContact.data.seo_image.alt
        }
      />

      {/* ============ INTRO ============ */}
      <IntroSection
        id="contact-intro"
        data={{
          title:
            finalData.prismicContact.data.hero_title &&
            finalData.prismicContact.data.hero_title.text,
          buttonText: finalData.prismicContact.data.quote_button_text,
        }}
      />

      {/* ============ MORE INFO ============ */}
      <MoreInfoSection
        id="contact-moreInfo"
        data={{
          block1: {
            title:
              finalData.prismicContact.data.info_block_1_title &&
              finalData.prismicContact.data.info_block_1_title.text,
            subtitle: finalData.prismicContact.data.info_block_1_subtitle,
            buttonText: finalData.prismicContact.data.info_block_1_button_text,
            buttonLink: finalData.prismicContact.data.info_block_1_button_link,
          },
          block2: {
            title:
              finalData.prismicContact.data.info_block_2_title &&
              finalData.prismicContact.data.info_block_2_title.text,
            subtitle: finalData.prismicContact.data.info_block_2_subtitle,
            buttonText: finalData.prismicContact.data.info_block_2_button_text,
            buttonLink: finalData.prismicContact.data.info_block_2_button_link,
          },
        }}
      />

      {/* ============ CONTACT ============ */}
      <ContactSection
        id="contact-contact"
        data={{
          description:
            finalData.prismicContact.data.contact_description &&
            finalData.prismicContact.data.contact_description.html,
          mapImage:
            finalData.prismicContact.data.map_image &&
            finalData.prismicContact.data.map_image.fluid &&
            finalData.prismicContact.data.map_image,
        }}
      />

      {/* ============ QUOTE ============ */}
      <QuoteSection
        id="quote"
        data={{
          bodyContent:
            finalData.prismicContact.data.quote_description &&
            finalData.prismicContact.data.quote_description.html,
          services: finalData.allPrismicService.nodes,
          bins: finalData.allPrismicBin.nodes,
          features: finalData.prismicContact.data.quote_features,
        }}
      />
    </>
  )
}

export default ContactPage
